import {BB} from '../../../bb/bb';
import {KL} from '../../kl';
import {klHistory} from '../../history/kl-history';
import {IKeyString} from '../../../bb/bb-types';
import {StatusOverlay} from '../components/status-overlay';
import {KlCanvasWorkspace} from '../../canvas-ui/kl-canvas-workspace';
import {KlCanvas} from '../../canvas/kl-canvas';
import {LANG} from '../../../language/language';
import {IFilterApply, IFilterGetDialogParam, IFilterGetDialogResult} from '../../kl-types';
import {KlColorSlider} from '../components/kl-color-slider';
import {LayerManager} from './layer-manager/layer-manager';
import {HandUi} from './hand-ui';
import {RGB} from '../../../bb/color/color';


export class FilterTab {

    private readonly rootEl: HTMLDivElement;
    private isInit = false;

    constructor (
        private klRootEl: HTMLElement,
        private klColorSlider: KlColorSlider,
        private layerManager: LayerManager,
        private klCanvasWorkspace: KlCanvasWorkspace,
        private handUi: HandUi,
        private getCurrentColor: () => RGB,
        private getKlMaxCanvasSize: () => number,
        private getKlCanvas: () => KlCanvas,
        private getCurrentLayerCtx: () => CanvasRenderingContext2D | null,
        private isEmbed: boolean,
        private statusOverlay: StatusOverlay,
    ) {
        this.rootEl = document.createElement('div');
    }

    private init (): void {
        const filters = KL.filterLib;
        const buttons = [];

        if (!KL.filterLibStatus.isLoaded) {
            throw new Error('filters not loaded');
        }

        const createButton = (filterKey: string): HTMLElement => {
            const button = document.createElement('button');
            const buttonLabel = LANG(filters[filterKey].lang.button);
            const imClass = filters[filterKey].darkNoInvert ? 'class="dark-no-invert"' : '';
            const im = '<img ' + imClass + ' height="20" width="18" src="' + filters[filterKey].icon + '" alt="icon" />';
            button.innerHTML = im + buttonLabel;
            button.className = 'grid-button';
            BB.css(button, {
                lineHeight: '20px',
                fontSize: '12px',
            });
            button.tabIndex = -1;

            const filterName = LANG(filters[filterKey].lang.name);

            button.onclick = () => {

                const finishedDialog = (result, filterDialog): void => {
                    if (result == 'Cancel') {
                        if (filterDialog.destroy) {
                            filterDialog.destroy();
                        }
                        return;
                    }
                    let input;
                    try {
                        input = filterDialog.getInput(); // also destroys
                    } catch (e) {
                        if (e.message.indexOf('.getInput is not a function') !== -1) {
                            throw 'filterDialog.getInput is not a function, filter: ' + filterName;
                        } else {
                            throw e;
                        }
                    }
                    applyFilter(input);
                };

                if (!('apply' in filters[filterKey])) {
                    alert('Application not fully loaded');
                    return;
                }

                const applyFilter = (input: any) => {
                    const filterResult = filters[filterKey].apply({
                        context: this.getCurrentLayerCtx(),
                        klCanvas: this.getKlCanvas(),
                        history: klHistory,
                        input: input,
                    } as IFilterApply);
                    if (filterResult === false) {
                        alert("Couldn't apply the edit action");
                    }
                    if (filters[filterKey].updatePos === true) {
                        this.klCanvasWorkspace.resetView();
                        this.handUi.update(this.klCanvasWorkspace.getScale(), this.klCanvasWorkspace.getAngleDeg());
                    }
                    this.layerManager.update();
                };

                if (filters[filterKey].isInstant){
                    button.blur();
                    applyFilter(null);
                    this.statusOverlay.out('"' + filterName + '" ' + LANG('filter-applied'), true);
                } else {
                    const secondaryColorRGB = this.klColorSlider.getSecondaryRGB();
                    const filterDialog = filters[filterKey].getDialog({
                        context: this.getCurrentLayerCtx(),
                        klCanvas: this.getKlCanvas(),
                        maxWidth: this.getKlMaxCanvasSize(),
                        maxHeight: this.getKlMaxCanvasSize(),
                        currentColorRgb: {r: this.getCurrentColor().r, g: this.getCurrentColor().g, b: this.getCurrentColor().b},
                        secondaryColorRgb: {r: secondaryColorRGB.r, g: secondaryColorRGB.g, b: secondaryColorRGB.b},
                    } as IFilterGetDialogParam) as IFilterGetDialogResult;

                    if (!filterDialog) {
                        return;
                        //alert('Error: could not perform action');
                        //throw('filter['+filterKey+'].getDialog returned '+filterDialog+'. ctx:' + currentLayerCtx + ' klCanvas:' + klCanvas);
                    }

                    let closeFunc: () => void;
                    // Todo should move into getDialogParams
                    filterDialog.errorCallback = (e) => {
                        setTimeout(() => {
                            alert('Error: could not perform action');
                            throw e;
                        }, 0);
                        closeFunc();
                    };


                    const style: IKeyString = {};
                    if ('width' in filterDialog) {
                        style.width = filterDialog.width + 'px';
                    }

                    KL.popup({
                        target: this.klRootEl,
                        message: '<b>' + filterName + '</b>',
                        div: filterDialog.element,
                        style: style,
                        buttons: ['Ok', 'Cancel'],
                        clickOnEnter: 'Ok',
                        callback: (result) => {
                            finishedDialog(result, filterDialog);
                        },
                        closeFunc: (func) => {
                            closeFunc = func;
                        },
                    });
                }
            };
            buttons[buttons.length] = button;
            return button;
        };

        const addGroup = (groupArr: string[]): void => {
            Object.entries(filters).forEach(([filterKey, filter]) => {
                if (!groupArr.includes(filterKey)) {
                    return;
                }
                if (this.isEmbed && !filter.inEmbed) {
                    return;
                }
                this.rootEl.append(createButton(filterKey));
            });
        };

        const groupA = [
            'cropExtend',
            'flip',
            'perspective',
            'resize',
            'rotate',
            'transform',
        ];
        const groupB = [
            'brightnessContrast',
            'curves',
            'distort',
            'hueSaturation',
            'invert',
            'tiltShift',
            'toAlpha',
            'blur',
            'unsharpMask',
        ];
        const groupC = [
            'grid',
            'noise',
            'pattern',
            'vanishPoint',
        ];

        addGroup(groupA);
        this.rootEl.append(BB.el({className: 'grid-hr'}));
        addGroup(groupB);
        this.rootEl.append(BB.el({className: 'grid-hr'}));
        addGroup(groupC);

        this.isInit = true;
    }

    getElement (): HTMLElement {
        return this.rootEl;
    }

    show (): void {
        if (!this.isInit) {
            this.init();
        }
        this.rootEl.style.display = 'block';
    }

    hide (): void {
        this.rootEl.style.display = 'none';
    }

}